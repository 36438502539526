// bootstrap vars Body
$body-bg:#f8fafc;
$primary:#000000 !default;
$secondary:#000000 !default;
$body-color:#000000;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

// BELOW ARE DEFAULT COLOURS FOR BASE THEME
:root {
    --back-color: black;
    --font-color: #fff;
    --font-highlight: #fff;
    --border-color: #fff;
    --back-highlight: #484848;
    --land-background: #484848;
    --footer-color: black;
    --mobile-color: black;
}